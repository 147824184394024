<template>
  <div class="header-broker-menus">
    <div class="container-broker-menu">
      <div class="header-broker-menus-content">
        <div class="header-broker-menus-content-left-side">
          <div class="header-broker-menus-content-left-side-header">
            <h1>Best Brokers For Online Trading</h1>

            <!-- <h1></h1> -->
          </div>

          <div class="header-broker-menus-content-left-side-p">
            <p>
              Welcome to Brokers Menu - the ultimate place for finding your next
              regulated broker. No matter what your level of trading, it is
              imperative that you work with a safe, secure and reliable broker.
              It is time for you to choose with confidence
            </p>
          </div>
        </div>

        <div class="header-broker-menus-content-right-side">
          <img
            class="header-graph-desktop"
            src="../../assets/broker-menu/header-graph.svg"
            alt=""
          />
          <img
            class="header-graph-1024"
            src="../../assets/broker-menu/header-graph-1024.svg"
            alt=""
          />
          <img
            class="header-graph-768"
            src="../../assets/broker-menu/header-graph-768.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.header-graph-1024 {
  display: none;
}

.header-graph-768 {
  display: none;
}
.header-graph-desktop {
  width: 500px;
}
.header-broker-menus-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
}

.header-broker-menus-content-left-side {
  max-width: 536px;
  width: 100%;
}

.header-broker-menus-content-left-side-header h1 {
  font-size: 62px;
  margin-bottom: 20px;
  font-weight: 900;
}
.header-broker-menus-content-left-side-p p {
  font-weight: 300;
  font-size: 21px;
}
@media (max-width: 1200px) {
  .header-graph-desktop {
    width: 450px;
  }
}
@media (max-width: 1024px) {
  .header-broker-menus-content-left-side-header h1 {
    font-size: 30px;
  }

  .header-broker-menus-content-left-side {
    max-width: 394px;
    margin-right: 20px;
  }

  .header-graph-desktop {
    display: none;
  }
  .header-graph-1024 {
    display: block;
  }

  .header-broker-menus-content-left-side-p p {
    font-size: 18px;
    font-weight: lighter;
  }
}

@media (max-width: 768px) {
  .header-broker-menus-content-left-side-header h1 {
    font-size: 26px;
  }

  .header-broker-menus-content-left-side {
    max-width: 292px;
    margin-right: 20px;
  }

  .header-broker-menus-content {
    margin-top: 40px;
  }

  .header-graph-768 {
    display: block;
    max-width: 100%;
    width: 500px;
  }
  .header-graph-1024 {
    display: none;
  }
}

@media (max-width: 500px) {
  .header-broker-menus-content {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .header-broker-menus-content-left-side-p p {
    font-size: 16px;
  }

  .header-broker-menus-content-left-side {
    margin-right: 0;
  }

  .header-graph-768 {
    display: block;
    margin-top: 25px;
    max-width: unset;
    width: unset;
  }
}
</style>